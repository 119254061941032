import React, {
  useRef,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';

import 'tailwindcss/tailwind.css';
import { ZoomProperties } from './types';
import styled from 'styled-components';

type PaperCropperProps = {
  imageSrc: string;
  inputWidth: number;
  inputHeight: number;
  zoomProperties: ZoomProperties;
  onChangeZoomProperties: (properties: ZoomProperties) => void;
  isPanoramic: boolean;
};

export type PaperCropperHandle = {
  captureImage: () => string | undefined;
};

const StyledCanvas = styled.canvas`
  image-rendering: pixelated;
`

const PaperCropper = forwardRef<PaperCropperHandle, PaperCropperProps>(
  (props, ref) => {
    const {
      zoomProperties,
      imageSrc,
      inputWidth,
      inputHeight,
      onChangeZoomProperties,
      isPanoramic,
    } = props;
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const [canvasSize, setCanvasSize] = useState({ width: 0, height: 0 });
    const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
    const [isDragging, setIsDragging] = useState(false);
    const [startDrag, setStartDrag] = useState({ x: 0, y: 0 });
    const [image, setImage] = useState<HTMLImageElement | null>(null);
    const [overlayOpacity, setOverlayOpacity] = useState(0.7);
    const [pinchStartDistance, setPinchStartDistance] = useState<number | null>(
      null
    );
    const isMobile = window.innerWidth < 768
    const [shouldReload, setShouldReload] = useState(false)

    const sensitivityFactor = 0.05;
    const zoomLevel = zoomProperties.zoomLevel;
    const previousZoomLevel = useRef(zoomProperties.zoomLevel);
    const dpr = window.devicePixelRatio || 1;

    useEffect(() => {
      const handleResize = () => {
        const parentElement = canvasRef.current?.parentElement;
        if (parentElement) {
          const { clientWidth, clientHeight } = parentElement;
          if (clientWidth === canvasSize.width) {
            return
          }
          const newHeight = Math.max(clientHeight, window.innerHeight * 0.33);
          setCanvasSize({ width: clientWidth, height: newHeight });
        }

        if (isMobile && window.innerWidth > 768) {
          setShouldReload(true)
        }

      };
      handleResize();
      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, [canvasSize.width]);

    useEffect(() => {
      if (shouldReload) {
        window.location.reload()
      }
    }, [shouldReload])

    useEffect(() => {
      const img = new Image();
      img.src = imageSrc;
      img.onload = () => setImage(img);
    }, [imageSrc]);

    useEffect(() => {
      if (zoomProperties.fromUser) return;
      const canvas = canvasRef.current;
      if (!canvas) return;

      const canvasCenterX = canvasSize.width / 2;
      const canvasCenterY = canvasSize.height / 2;

      const scale = zoomProperties.zoomLevel / previousZoomLevel.current;

      setDragOffset((prevOffset) => ({
        x: prevOffset.x + (canvasCenterX - prevOffset.x) * (1 - scale),
        y: prevOffset.y + (canvasCenterY - prevOffset.y) * (1 - scale),
      }));

      previousZoomLevel.current = zoomLevel;
    }, [zoomProperties.zoomLevel, canvasSize.width, canvasSize.height]);

    useEffect(() => {
      const canvas = canvasRef.current;
      if (canvas && canvasSize.width && canvasSize.height) {
        const context = canvas.getContext('2d');
        if (context) {
          if (window.innerWidth < 768) {
            canvas.width = canvasSize.width * dpr;
            canvas.height = canvasSize.height * dpr;
            context.scale(dpr, dpr);

            canvas.style.width = `${canvasSize.width}px`
            canvas.style.height = `${canvasSize.height}px`
          } else {
            canvas.width = canvasSize.width;
            canvas.height = canvasSize.height;
          }
          drawCanvas(context);
        }
      }
    }, [canvasSize, dragOffset, dpr, image, overlayOpacity, inputHeight, inputWidth, isPanoramic]);

    const drawCanvas = (context: CanvasRenderingContext2D) => {
      const { width, height } = canvasSize;
      const rectRatio = inputWidth / inputHeight;

      const reduceRatio = window.innerWidth < 768 ? 0.8 : 0.86;

      const rectHeight = Math.min(height * reduceRatio, (width * reduceRatio) / rectRatio);
      const rectWidth = rectHeight * rectRatio;
      if (window.innerWidth < 768 && canvasSize.height !== rectHeight + 100) {
        setCanvasSize({ width: canvasSize.width, height: rectHeight + 100 });
      }
      context.clearRect(0, 0, width, height);

      if (image) {
        if (isPanoramic) {
          const imageRatio = image.naturalWidth / image.naturalHeight;
          let imageWidth, imageHeight;

          if (rectHeight > rectWidth / imageRatio) {
            imageWidth = rectHeight * imageRatio;
            imageHeight = rectHeight;
          } else {
            imageWidth = rectWidth;
            imageHeight = rectWidth / imageRatio;
          }

          const minX = width / 2 - rectWidth / 2;
          const maxX = width / 2 + rectWidth / 2 - imageWidth;
          const minY = height / 2 - rectHeight / 2;
          const maxY = height / 2 + rectHeight / 2 - imageHeight;

          let offsetX = dragOffset.x;
          let offsetY = dragOffset.y;

          if (imageWidth > rectWidth) {
            offsetX = Math.min(Math.max(dragOffset.x, maxX), minX);
          } else {
            offsetX = width / 2 - imageWidth / 2;
          }

          if (imageHeight > rectHeight) {
            offsetY = Math.min(Math.max(dragOffset.y, maxY), minY);
          } else {
            offsetY = height / 2 - imageHeight / 2;
          }
          context.drawImage(
            image,
            0,
            0,
            image.naturalWidth,
            image.naturalHeight,
            offsetX,
            offsetY,
            imageWidth,
            imageHeight
          );
        } else {
          const imageAspectRatio = image.naturalWidth / image.naturalHeight;
          const canvasAspectRatio = width / height;

          let drawWidth = width;
          let drawHeight = height;

          if (imageAspectRatio > canvasAspectRatio) {
            drawHeight = height;
            drawWidth = height * imageAspectRatio;
          } else {
            drawWidth = width;
            drawHeight = width / imageAspectRatio;
          }

          const patternWidth = drawWidth * zoomLevel;
          const patternHeight = drawHeight * zoomLevel;

          const offsetX =
            (((dragOffset.x % patternWidth) + patternWidth) % patternWidth) -
            patternWidth;
          const offsetY =
            (((dragOffset.y % patternHeight) + patternHeight) % patternHeight) -
            patternHeight;

          context.save();
          context.translate(offsetX, offsetY);

          for (
            let x = -patternWidth;
            x < width + patternWidth;
            x += patternWidth
          ) {
            for (
              let y = -patternHeight;
              y < height + patternHeight;
              y += patternHeight
            ) {
              context.drawImage(
                image,
                0,
                0,
                image.naturalWidth,
                image.naturalHeight,
                x,
                y,
                patternWidth,
                patternHeight
              );
            }
          }

          context.restore();
        }
      }

      const centerX = width / 2;
      const centerY = height / 2;
      context.fillStyle = `rgba(255, 255, 255, ${overlayOpacity})`;

      context.fillRect(0, 0, width, centerY - rectHeight / 2);
      context.fillRect(
        0,
        centerY + rectHeight / 2,
        width,
        height - centerY - rectHeight / 2
      );
      context.fillRect(
        0,
        centerY - rectHeight / 2,
        centerX - rectWidth / 2,
        rectHeight
      );
      context.fillRect(
        centerX + rectWidth / 2,
        centerY - rectHeight / 2,
        width - centerX - rectWidth / 2,
        rectHeight
      );

      context.font = '16px Arial';
      context.fillStyle = 'black';
      context.textAlign = 'center';

      drawLines(
        context,
        centerX - rectWidth / 2,
        centerY + rectHeight / 2 + 10,
        centerX + rectWidth / 2,
        centerY + rectHeight / 2 + 10
      );
      context.fillText(
        `${inputWidth} cm`,
        centerX,
        centerY + rectHeight / 2 + 30
      );

      drawLines(
        context,
        centerX + rectWidth / 2 + 10,
        centerY - rectHeight / 2,
        centerX + rectWidth / 2 + 10,
        centerY + rectHeight / 2
      );
      context.save();
      context.rotate(-Math.PI / 2);
      context.fillText(
        `${inputHeight} cm`,
        -centerY,
        centerX + rectWidth / 2 + 30
      );
      context.restore();

      context.strokeStyle = 'black';
      context.lineWidth = 1;
      context.strokeRect(
        centerX - rectWidth / 2,
        centerY - rectHeight / 2,
        rectWidth,
        rectHeight
      );
    };

    const drawLines = (
      context: CanvasRenderingContext2D,
      startX: number,
      startY: number,
      endX: number,
      endY: number
    ) => {
      const lineLength = 5;
      const angle = Math.atan2(endY - startY, endX - startX);

      const perpendicularAngle1 = angle + Math.PI / 2;
      const perpendicularAngle2 = angle - Math.PI / 2;

      context.beginPath();
      context.moveTo(startX, startY);
      context.lineTo(endX, endY);
      context.stroke();

      context.beginPath();
      context.moveTo(
        startX + lineLength * Math.cos(perpendicularAngle1),
        startY + lineLength * Math.sin(perpendicularAngle1)
      );
      context.lineTo(
        startX + lineLength * Math.cos(perpendicularAngle2),
        startY + lineLength * Math.sin(perpendicularAngle2)
      );
      context.stroke();

      context.beginPath();
      context.moveTo(
        endX + lineLength * Math.cos(perpendicularAngle1),
        endY + lineLength * Math.sin(perpendicularAngle1)
      );
      context.lineTo(
        endX + lineLength * Math.cos(perpendicularAngle2),
        endY + lineLength * Math.sin(perpendicularAngle2)
      );
      context.stroke();
    };

    const handleMouseDown = (e: React.MouseEvent) => {
      setIsDragging(true);
      setOverlayOpacity(0.4);
      setStartDrag({ x: e.clientX, y: e.clientY });
    };

    const handleMouseMove = (e: React.MouseEvent) => {
      if (isDragging) {
        const deltaX = e.clientX - startDrag.x;
        const deltaY = e.clientY - startDrag.y;
        setDragOffset({ x: dragOffset.x + deltaX, y: dragOffset.y + deltaY });
        setStartDrag({ x: e.clientX, y: e.clientY });
      }
    };

    const handleMouseUp = () => {
      setIsDragging(false);
      setOverlayOpacity(0.7);
    };

    const handleTouchStart = (e: React.TouchEvent) => {
      e.stopPropagation();
      if (e.touches.length === 1) {
        const touch = e.touches[0];
        setIsDragging(true);
        setOverlayOpacity(0.4);
        setStartDrag({ x: touch.clientX, y: touch.clientY });
      } else if (e.touches.length === 2) {
        const touch1 = e.touches[0];
        const touch2 = e.touches[1];
        const distance = Math.hypot(
          touch1.clientX - touch2.clientX,
          touch1.clientY - touch2.clientY
        );
        setPinchStartDistance(distance);
      }
    };

    const handleTouchEnd = () => {
      setIsDragging(false);
      setOverlayOpacity(0.7);
      setPinchStartDistance(null);
    };

    const handleTouchMove = (e: React.TouchEvent) => {
      e.stopPropagation();
      const canvas = canvasRef.current;
      if (!canvas) return;

      const rect = canvas.getBoundingClientRect();

      if (isDragging && e.touches.length === 1) {
        const touch = e.touches[0];
        const deltaX = touch.clientX - startDrag.x;
        const deltaY = touch.clientY - startDrag.y;
        setDragOffset({ x: dragOffset.x + deltaX, y: dragOffset.y + deltaY });
        setStartDrag({ x: touch.clientX, y: touch.clientY });
      } else if (e.touches.length === 2) {
        const touch1 = e.touches[0];
        const touch2 = e.touches[1];

        const distance = Math.hypot(
          touch1.clientX - touch2.clientX,
          touch1.clientY - touch2.clientY
        );

        if (pinchStartDistance !== null) {
          const scale = distance / pinchStartDistance;
          const newZoomLevel = Math.max(
            0.1,
            zoomLevel * (1 + (scale - 1) * sensitivityFactor * 10)
          );

          const pointerX =
            (((touch1.clientX + touch2.clientX) / 2 - rect.left) / rect.width) *
            canvasSize.width;
          const pointerY =
            (((touch1.clientY + touch2.clientY) / 2 - rect.top) / rect.height) *
            canvasSize.height;

          const scaleChange = newZoomLevel / zoomLevel;
          setDragOffset({
            x: dragOffset.x + (pointerX - dragOffset.x) * (1 - scaleChange),
            y: dragOffset.y + (pointerY - dragOffset.y) * (1 - scaleChange),
          });

          onChangeZoomProperties({ zoomLevel: newZoomLevel, fromUser: true });
        }

        setPinchStartDistance(distance);
      }
    };

    useEffect(() => {
      const canvas = canvasRef.current;
      const preventScroll = (e: Event) => e.preventDefault();
      canvas?.addEventListener('touchmove', preventScroll, { passive: false });

      const handleGlobalWheel = (e: WheelEvent) => {
        e.preventDefault();
        e.stopPropagation();
      };

      canvas?.addEventListener('wheel', handleGlobalWheel, { passive: false });

      return () => {
        canvas?.removeEventListener('touchmove', preventScroll);
        canvas?.removeEventListener('wheel', handleGlobalWheel);
      };
    }, []);

    useImperativeHandle(ref, () => ({
      captureImage: () => {
        const canvas = canvasRef.current;
        if (canvas && image) {
          const context = canvas.getContext('2d');
          if (context) {
            let { width, height } = canvasSize;
            width = window.innerWidth < 768 ? width * dpr : width
            height = window.innerWidth < 768 ? height * dpr : height
            const rectRatio = inputWidth / inputHeight;
            const reduceRatio = window.innerWidth < 768 ? 0.8 : 0.86;
            const rectHeight = Math.min(height * reduceRatio, (width * reduceRatio) / rectRatio);

            const rectWidth = rectHeight * rectRatio;

            const centerX = width / 2;
            const centerY = height / 2;

            const x = centerX - rectWidth / 2;
            const y = centerY - rectHeight / 2;

            const imageData = context.getImageData(x, y, rectWidth, rectHeight);
            const downloadCanvas = document.createElement('canvas');
            const downloadContext = downloadCanvas.getContext('2d');
            if (downloadContext) {
              downloadCanvas.width = rectWidth;
              downloadCanvas.height = rectHeight;

              downloadContext.putImageData(imageData, 0, 0);

              const dataUrl = downloadCanvas.toDataURL('image/jpeg', 0.5);
              return dataUrl
            }
          }
        }
      },
    }));

    return (
      <div className='h-full w-full overflow-hidden'>
        <StyledCanvas
          ref={canvasRef}
          className='block cursor-move'
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        />
      </div>
    );
  }
);

export default PaperCropper;
