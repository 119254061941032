import { FC, RefObject, useState } from 'react';
import { Product, ProductQuality, ProductVariation, ZoomProperties } from './types';
import { PaperCropperHandle } from './PaperCropper';
import { ProductQualityCard } from './ProductQualityCard';
import { Minus, Plus } from '@phosphor-icons/react';
import { SeparatorLine } from './SeparatorLine';
import { addToCart, getRollSize } from './utils';
import { VariationIcon } from './VariationIcon';

interface ConfiguratorRightPanelProps {
  product: Product;
  height: number;
  width: number;
  zoomProperties: ZoomProperties;
  onChangeHeight: (height: number) => void;
  onChangeWidth: (width: number) => void;
  onChangeZoomProperties: (properties: ZoomProperties) => void;
  paperCropperRef: RefObject<PaperCropperHandle | null>;
  isPanoramic: boolean;
  setSelectedVariation: (variation: ProductVariation) => void;
  selectedVariation: ProductVariation | null;
}

export const ConfiguratorRightPanel: FC<ConfiguratorRightPanelProps> = (
  props
) => {
  const {
    product,
    height,
    width,
    zoomProperties,
    onChangeHeight,
    onChangeWidth,
    onChangeZoomProperties,
    setSelectedVariation,
    selectedVariation,
    paperCropperRef,
    isPanoramic,
  } = props;

  const [isLoading, setIsLoading] = useState(false);

  const [selectedQuality, setSelectedQuality] = useState<ProductQuality>(
    ProductQuality.BASIC
  );
  const [openInfoText, setOpenInfoText] = useState(false);

  const totalOrderSize = (height * width) / 10000;
  const totalOrderPrice = (basePrice: number) => (
    (totalOrderSize < 1 ? 1 : totalOrderSize) *
    (basePrice + (selectedQuality === ProductQuality.BASIC ? 0 : 5))
  ).toFixed(2);
  const rollSize = getRollSize(width)
  const rollsCount = width / rollSize

  const salePrice = selectedVariation?.salePrice ?? product.salePrice;
  const regularPrice = selectedVariation?.regularPrice ?? product.regularPrice;
  const onSale = selectedVariation?.onSale ?? product.onSale;
  const isMobile = window.innerWidth < 768 

  return (
    <div className='border-1 m-2 h-full rounded-lg border-solid border-gray-700 bg-white px-4 py-4 shadow md:py-0 overflow-y-auto'>
      <form className='w-full mb-4'>
        <h2 className={'pt-2 font-medium'}>Configurez votre papier peint</h2>
        <SeparatorLine />
        <div className='flex w-full gap-2'>
          <div className='w-full'>
            <label
              htmlFor='width'
              className='mb-2 block text-sm font-medium text-gray-900'
            >
              Largeur (en cm)
            </label>
            <input
              type='number'
              id='width'
              value={width === 0 ? '' : width}
              onChange={(e) => {
                const newValue = parseInt(e.target.value, 10)
                onChangeWidth(isNaN(newValue) ? 0 : Number(newValue));}
              }
              className='block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-base text-gray-900 focus:border-blue-500 focus:ring-blue-500'
              placeholder=''
              required
            />
          </div>
          <div className='w-full'>
            <label
              htmlFor='height'
              className='mb-2 block text-sm font-medium text-gray-900'
            >
              Hauteur (en cm)
            </label>
            <input
              type='number'
              id='height'
              value={height === 0 ? '' : height}
              onChange={(e) => {
                const newValue = parseInt(e.target.value, 10)
                onChangeHeight(isNaN(newValue) ? 0 : Number(newValue));}
              }
              className='block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-base text-gray-900 focus:border-blue-500 focus:ring-blue-500'
              placeholder=''
              required
            />
          </div>
        </div>
        <div
          className={'w-full cursor-pointer pt-2'}
          onClick={() => setOpenInfoText(!openInfoText)}
        >
          <div className={'flex gap-1 items-center'}>
            <p className={'flex-1 text-sm text-left'}>
              Nous vous conseillons d’ajouter une marge de 10cm en largeur et en
              hauteur
            </p>
            {openInfoText ? <Minus size={16} /> : <Plus size={16} />}
          </div>
        </div>
        {openInfoText && (
          <p className={'pt-2 text-sm text-gray-500'}>
            Il est fréquent que les murs et plafonds ne soient pas parfaitement
            droits, c’est pourquoi nous conseillons de prévoir une marge de 10cm
            en hauteur et en largeur et de les ajouter aux mesures prises afin
            de vous faciliter la pose.
          </p>
        )}
        <SeparatorLine />

        {!isPanoramic && (
          <div>
            <div>
              <label
                htmlFor='zoomLevel'
                className='block text-sm font-medium text-gray-900'
              >
                Niveau de zoom
              </label>
              <input
                type='range'
                className='w-full text-white accent-[#b25c34]'
                min={isMobile ? 25 : 20}
                max={90}
                id='zoomLevel'
                value={zoomProperties.zoomLevel * 100}
                onChange={(e) =>
                  onChangeZoomProperties({
                    zoomLevel: parseFloat(e.target.value) / 100,
                    fromUser: false,
                  })
                }
              />
              <p className={'text-sm'}>Créez votre papier peint sur-mesure grâce à la fonction de zoom. Vous pouvez agrandir ou rétrécir le motif selon votre inspiration!</p>
            </div>
            <SeparatorLine />
          </div>
        )}
        {product.variations.length ?
          <div>
            <p className={'text-sm font-medium text-gray-900 pb-2'}>Couleurs</p>
            <div className={'flex gap-2'}>
              {product.variations.map(variation =>
                <VariationIcon
                  color={variation.color}
                  onClick={() => setSelectedVariation(variation)}
                  isSelected={selectedVariation?.id === variation.id}
                  pictoSrc={`${anatoleConfig.assetsUrl}${variation.color ?? 'multicolore'}.webp`} />
              )}
            </div>
            <SeparatorLine />

          </div> : null}
        <ProductQualityCard
          onSale={onSale}
          regularPrice={regularPrice}
          salePrice={salePrice}
          onClick={() => setSelectedQuality(ProductQuality.BASIC)}
          description={
            'Le bon rapport qualité prix. Papier peint intissé 170g/m². Finition satinée. Encollage direct sur le mur. Prédécoupé à vos dimensions et prêt à poser.'
          }
          title={'Standard'}
          selected={selectedQuality === ProductQuality.BASIC}
        />
        <ProductQualityCard
          onSale={onSale}
          regularPrice={regularPrice + 5}
          salePrice={salePrice + 5}
          onClick={() => setSelectedQuality(ProductQuality.PREMIUM)}
          description={
            'Notre best-seller. Papier peint intissé 185g/m². Plus épais, très facile à poser, indéchirable. Encollage direct sur le mur. Masque les légères imperfections du mur. Prédécoupé à vos dimensions et prêt à poser. Lessivable avec une éponge et du savon.'          }
          title={'Premium'}
          selected={selectedQuality === ProductQuality.PREMIUM}
        />
        <SeparatorLine />

        <div className={'flex w-full pb-4'}>
          <div className={'block w-full'}>
            <span className={'text-sm font-semibold'}>Total</span>
             <div
              className={'text-xs'}
            >{isNaN(rollSize) ? `0 lé de 0cm, pour 0m²` : `${rollsCount.toFixed()} lé${rollsCount >= 2 ? 's' : ''} de ${rollSize.toFixed(1)}cm, pour ${totalOrderSize}m²`}
            </div>
          </div>
          <div className={'flex gap-2'}>
            {onSale && <span className={'text-lg line-through'}>{totalOrderPrice(regularPrice)}€</span>}
            <span className={'text-lg'}>{totalOrderPrice(selectedVariation?.salePrice ?? product.salePrice)}€</span>
          </div>
        </div>

        <div className={'flex-col flex gap-2'}>
          <button
            type='button'
            disabled={isLoading}
            onClick={() => {
              if (paperCropperRef?.current) {
                const previewImage = paperCropperRef.current.captureImage();
                setIsLoading(true);
                addToCart(anatoleConfig.ajax_url,
                  product.id.toString(),
                  selectedVariation?.id ?? '',
                  selectedVariation?.color ?? '',
                  height.toString(),
                  width.toString(),
                  selectedQuality.toString(),
                  previewImage ?? '');
              }
            }}
            className='rounded-lg border border-gray-300 bg-white px-5 py-2.5 text-sm font-medium text-gray-900 hover:bg-gray-100 focus:outline-none focus:ring-4 focus:ring-gray-100 dark:border-gray-600 dark:bg-gray-800 dark:text-white dark:hover:border-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-700'
          >
            Ajouter au panier
          </button>
          {isLoading && <span>Ajout au panier en cours...</span>}
        </div>
      </form>
    </div>
  );
};
