import { Product, ZoomProperties } from './types';
import { ConfiguratorRightPanel } from './ConfiguratorRightPanel';
import styled from 'styled-components';
import PaperCropper, { PaperCropperHandle } from './PaperCropper';
import React, {
  useRef,
  useState,
} from 'react';
import { useDebounce } from "use-debounce";

interface ConfiguratorProps {
  product: Product;
}

const StyledDiv = styled.div<{ bgColor?: string }>`
  background-color: ${(props) => props.bgColor ?? 'none'};
`;

export const Configurator: React.FC<ConfiguratorProps> = (props) => {
  const { product } = props;
  const [width, setWidth] = useState(300);
  const [height, setHeight] = useState(250);
  const [zoomProperties, setZoomProperties] = useState<ZoomProperties>({zoomLevel: 0.5, fromUser: false});
  const [selectedVariation, setSelectedVariation] = useState(product.variations.length ? product.variations[0] : null)

  const cropperRef = useRef<PaperCropperHandle | null>(null);
  const isPanoramic = product.categories.toLowerCase().includes('panoramique')

  const [debouncedWidth] = useDebounce(width, 500);
  const [debouncedHeight] = useDebounce(height, 500);
  
  return (
    <div className='mt-[-19.2px] overscroll-none md:h-[100vh] bg-gray-100'>
      <div className='h-full md:flex md:flex-row'>
      <StyledDiv
          className='overscroll-none sm:p-6 md:h-[90%] md:w-2/3 min-h-[33vh] flex items-center'
        >
          {debouncedHeight > 0 && debouncedWidth > 0 && <PaperCropper 
            inputHeight={debouncedHeight}
            inputWidth={debouncedWidth}
            ref={cropperRef}
            imageSrc={selectedVariation ? selectedVariation.patternUrl : product.patternUrl}
            zoomProperties={zoomProperties}
            onChangeZoomProperties={setZoomProperties}
            isPanoramic={isPanoramic}
          />}
        </StyledDiv>
        <div className='h-full md:w-1/3'>
       
          <ConfiguratorRightPanel
            isPanoramic={isPanoramic}
            product={product}
            height={height}
            width={width}
            onChangeHeight={setHeight}
            onChangeWidth={setWidth}
            paperCropperRef={cropperRef}
            zoomProperties={zoomProperties}
            onChangeZoomProperties={setZoomProperties}
            setSelectedVariation={setSelectedVariation}
            selectedVariation={selectedVariation}
          />
        </div>
      </div>
    </div>
  );
};
