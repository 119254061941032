export type Product = {
  id: number;
  name: string;
  salePrice: number;
  regularPrice: number;
  priceHtml: string;
  onSale: boolean;
  patternUrl: string;
  categories: string;
  variations: ProductVariation[]
};

export type ProductVariation = {
  id: string;
  color: string;
  salePrice: number;
  regularPrice: number;
  patternUrl: string;
  onSale: boolean;
}

export type ZoomProperties = { zoomLevel: number; fromUser: boolean };

export enum ProductQuality {
  BASIC,
  PREMIUM
}