import React, { StrictMode } from "react";
import { createRoot } from "@wordpress/element"
import { toProduct } from "./utils";
import { Configurator } from "./Configurator";
import './style/main.scss';

const container = document.getElementById("react-app")

if (!container) {
  throw new Error("Container element with id 'react-app' not found.");
}

const productAttribute = container.getAttribute("data-product");
 if (!productAttribute) {
  throw new Error("Data attribute 'data-product' not found on the container element.");
}
const product = toProduct(productAttribute);
const root = createRoot(container)
root.render(
  <StrictMode>
    <Configurator product={product}/>
  </StrictMode>
)