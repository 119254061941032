import { FC } from 'react';
 
interface ProductQualityCardProps {
  onClick: () => void;
  selected: boolean;
  description?: string;
  title: string;
  salePrice: number;
  regularPrice: number;
  onSale: boolean;
}

export const ProductQualityCard: FC<ProductQualityCardProps> = (props) => {
  const { onClick, selected, description, title, salePrice, regularPrice, onSale } = props;
  return (
    <div
      className={`relative my-2 w-full rounded-lg border p-4 shadow hover:border-[#b25c34] hover:bg-gray-100 ${selected ? 'border-[#b25c34] bg-gray-100' : 'border-gray-200 bg-white'}`}
      onClick={onClick}
    >
  
      <div className={'flex justify-between'}>
        <span className={'text-sm font-semibold'}>{title}</span>

        <div className="flex gap-2"> {onSale && <div className={'flex items-baseline line-through'}>
          <span className={'text-sm font-semibold'}>{regularPrice}€</span>
          <span className={'text-xs text-gray-500'}>/m²</span>
        </div>}
        <div className={'flex items-baseline'}>
          <span className={'text-sm font-semibold'}>{salePrice}€</span>
          <span className={'text-xs text-gray-500'}>/m²</span>
        </div>
        </div>
      </div>

      <p className={'text-sm text-gray-500'}>{description}</p>
    </div>
  );
};
