import { Product, ProductVariation } from './types';

export function toProduct(rawData: string): Product {
  const rawDataJson = JSON.parse(rawData);
  return {
    name: rawDataJson.name,
    salePrice: rawDataJson.sale_price,
    priceHtml: rawDataJson.priceHtml,
    onSale: rawDataJson.on_sale,
    patternUrl: rawDataJson.pattern_url,
    id: rawDataJson.id,
    categories: rawDataJson.categories,
    variations: toVariations(rawDataJson.variations),
    regularPrice: Boolean(rawDataJson.on_sale)
      ? Number(
          rawDataJson.price_html
            .split('woocommerce-Price-amount amount"><bdi>')[1]
            .split('<span class="woocommerce-Price-currencySymbol')[0]
            .replace(/,/g, '.')
        )
      : rawDataJson.sale_price,
  };
}

function toVariations(rawDataArray: any): ProductVariation[] {
  if (rawDataArray.length > 0) {
    return rawDataArray.map(
      (rawVariationData: any) =>
        ({
          id: rawVariationData.id,
          salePrice: rawVariationData.sale_price,
          regularPrice: rawVariationData.regular_price,
          onSale: rawVariationData.on_sale,
          patternUrl: rawVariationData.pattern_url,
          color: rawVariationData.color,
        }) as ProductVariation
    );
  }
  return [];
}

export function getRollSize(totalWidth: number): number {
  return totalWidth / Math.ceil(totalWidth / 100);
}

export function addToCart(
  url: string,
  productId: string,
  variationId: string,
  color: string,
  height: string,
  width: string,
  paperQuality: string,
  previewImage: string
): void {
  const nonce = anatoleConfig.nonce;

  fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: new URLSearchParams({
      action: 'add_to_cart',
      productId,
      height,
      width,
      paperQuality,
      variationId,
      previewImage,
      color,
      security: nonce,
    }),
  })
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      if (result.success) {
        window.location.href = '/panier';
      } else {
        console.warn('Failed to add product to cart');
      }
    })
    .catch((error) => {
      console.error('An error occurred while adding the product to the cart.');
      alert('An error occurred while adding the product to the cart.');
    });
}
