import { FC } from 'react';
import styled from 'styled-components';
import { ProductVariation } from './types';

interface VariationIconProps {
  onClick: () => void;
  isSelected: boolean;
  pictoSrc: string;
  color?: string;
}

const StyledDiv = styled.div<{ src: string }>`
  background-image: url(${({ src }) => src});
`;

export const VariationIcon: FC<VariationIconProps> = (props) => {
  const { isSelected, onClick, pictoSrc, color } = props;

  return (
    <StyledDiv
      onClick={onClick}
      className={`ursor-pointer h-8 w-8 rounded-full border-2 object-cover hover:border-[#b25c34] ${isSelected ? 'border-[#b25c34]' : 'border-gray-300'}`}
      src={pictoSrc}
    />
  );
};
